import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"

export default class News extends React.Component {

  renderNews() {
    return null
  }

  render() {
    const { news } = this.props.data
    return (
      <Layout lang="ru">
        <Helmet>
          <title>{ news.title } — Catenaccio</title>
        </Helmet>

        <section className="container news-article-header">
          <div className="row">
            <div className="col-xl-12">
              <h1>{ news.title }{ news.subtitle && '.'} <span>{ news.subtitle }</span></h1>
              <p className="news-article-meta">{ news.date } <span>Источник: { news.source ? <a href={ news.source } target="_blank">{ news.source_name }</a> : 'Catenaccio'}</span></p>
              <img src={ news.image.childImageSharp.fluid.src } width="100%" />
            </div>
          </div>
        </section>

        <article>
          <section className="container text-section">{ this.renderNews() }</section>
        </article>
      </Layout>
    )
  }
}

export const query = graphql`
fragment NewsInfo on NewsYaml {
  title
  subtitle
  date
  source
  source_name
  image {
    childImageSharp {
      fluid(maxWidth: 1680) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`